import React from 'react';

// Types
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';

// Components
import {
  ComptButton,
  ComptButtonSize,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {SidebarCollapsible} from '@compt/common/compt-sidebar-dropdown/compt-sidebar-collapsible';

const STATUS_OPTIONS = [
  {id: LearningRequestStatus.PRE_APPROVAL_REQUESTED, label: 'Pre-approval requested'},
  {id: LearningRequestStatus.PARTIALLY_APPROVED, label: 'Partially approved'},
  {id: LearningRequestStatus.PRE_APPROVED, label: 'Pre-approved'},
  {id: LearningRequestStatus.PRE_APPROVAL_REJECTED, label: 'Pre-approval rejected'},
  {id: LearningRequestStatus.REIMBURSEMENT_REQUESTED, label: 'Reimbursement requested'},
  {id: LearningRequestStatus.REIMBURSEMENT_APPROVED, label: 'Reimbursement approved'},
  {id: LearningRequestStatus.REIMBURSED, label: 'Reimbursed'},
  {id: LearningRequestStatus.REIMBURSEMENT_REJECTED, label: 'Reimbursement rejected'},
];

const FILTER_OPTIONS = [
  // {id: 'country', label: 'Country', testId: 'review-lnd-country-filter'},
  // {id: 'currency', label: 'Currency', testId: 'review-lnd-currency-filter'},
  // {id: 'department', label: 'Department', testId: 'review-lnd-department-filter'},
  {id: 'status', label: 'Status', testId: 'review-lnd-status-filter'},
  // {id: 'amount', label: 'Amount', testId: 'review-lnd-amount-filter'},
  // {id: 'receipt_date', label: 'Receipt date', testId: 'review-lnd-receipt-date-filter'},
  // {id: 'date_submitted', label: 'Date submitted', testId: 'review-lnd-date-submitted-filter'},
  // {id: 'stipend', label: 'Stipend', testId: 'review-lnd-stipend-filter'},
  // {id: 'category', label: 'Category', testId: 'review-lnd-category-filter'},
  // {id: 'taxability', label: 'Taxability', testId: 'review-lnd-taxability-filter'},
];

interface FilterSidebarProps {
  onHide: () => void;
  selectedStatuses: string[];
  setSelectedStatuses: (statuses: string[]) => void;
}

export const ReviewLndFilterSidebar: React.FC<FilterSidebarProps> = ({
  onHide,
  selectedStatuses,
  setSelectedStatuses,
}) => {
  const handleStatusChange = (status: string) => {
    if (selectedStatuses.includes(status)) {
      setSelectedStatuses(selectedStatuses.filter((s) => s !== status));
    } else {
      setSelectedStatuses([...selectedStatuses, status]);
    }
  };

  const handleClearAll = () => {
    setSelectedStatuses([]);
  };

  return (
    <div className="flex flex-col p-4 flex-grow">
      <div className="flex justify-between items-center">
        <p className="text-md text-color-body1 font-bold">Filter</p>
        {selectedStatuses?.length > 0 && (
          <ComptButton
            buttonType={ComptButtonType.OUTLINED}
            onClick={() => handleClearAll()}
            size={ComptButtonSize.SMALL}
            className="py-1 px-0 min-w-[70px]"
          >
            Clear all
          </ComptButton>
        )}
      </div>
      <div className="flex flex-col mt-4 space-y-2">
        {FILTER_OPTIONS.map((filter) => (
          <SidebarCollapsible
            key={`side-bar-collapse-${filter.id}`}
            collapsibleId={filter.id}
            label={filter.label}
            options={STATUS_OPTIONS}
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) => option.label}
            selectedOptions={selectedStatuses}
            handleSelectionChange={(id, checked) => handleStatusChange(id)}
          />
        ))}
      </div>

      <button className="mt-auto py-2 text-md text-left font-medium text-primary" onClick={onHide}>
        Hide filter
      </button>
    </div>
  );
};
