import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

// RTK queries
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetCommunityFeedQuery} from '@compt/app/services/api/community-feed-slice';

// Hooks and methods
import {useDebounce} from '@uidotdev/usehooks';
import {useInfiniteScrollObserver} from '@compt/utils/infinite-scroll-helper';

// Types
import {MAX_15_PAGE_LIMIT} from '@compt/constants';
import {LearningCourseReview} from '@compt/types/community-feed';
import {FormattedFilterObject} from '@compt/common/compt-filter-bar/compt-filter-bar.types';

// Components
import {ComptCommunityCard} from '@compt/common/compt-community-card/compt-community-card';
import {SearchFilter} from '@compt/common/compt-filter-bar/compt-search-filter';
import {ComptLoadingAnimation} from '@compt/common/compt-loading/compt-loading-animation';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';

interface CommunityFeedProps {
  filters: Record<string, string[]>;
  setFilters: Dispatch<SetStateAction<Record<string, string[]>>>;
}

export const CommunityFeed = (props: CommunityFeedProps) => {
  const {filters, setFilters} = props;

  const [offset, setOffset] = useState<number>(0);
  const [reviews, setReviews] = useState<LearningCourseReview[]>([]);

  const loadMoreRef = useRef<HTMLDivElement>(null);
  const previousReviewCount = useRef<number>(0);

  const sessionQuery = useGetSessionQuery();
  const companyQuery = useGetCompanyQuery(sessionQuery.data?.user_id ?? skipToken);

  const formatFilterQuery = useMemo(() => {
    interface CommunityFeedFilter extends FormattedFilterObject {
      user_entered_keywords: string;
      authors_industry__in: string;
      only_company_reviews: string;
    }

    const filterToSend: CommunityFeedFilter = {
      user_entered_keywords: '',
      authors_industry__in: '',
      only_company_reviews: '',
    };

    const searchFilters = filters?.search;
    const industryFilters = filters?.industry;
    const isOnlyCompanyChecked = filters?.company && filters.company.length > 0;

    // Searching user entered keywords
    if (Array.isArray(searchFilters) && searchFilters.length > 0) {
      filterToSend.user_entered_keywords = searchFilters.join(',');
      setOffset(0);
    }

    // Selecting industries
    if (Array.isArray(industryFilters) && industryFilters.length > 0) {
      filterToSend.authors_industry__in = industryFilters.join(',');
      setOffset(0);
    }

    // Selecting only reviews from your company
    if (isOnlyCompanyChecked) {
      filterToSend.only_company_reviews = 'true';
      setOffset(0);
    }

    return filterToSend;
  }, [filters]);

  const debouncedQueryValues = useDebounce(formatFilterQuery, 300);

  const {
    data: reviewData,
    isLoading,
    isFetching,
  } = useGetCommunityFeedQuery({
    filter: {
      ...debouncedQueryValues,
      limit: MAX_15_PAGE_LIMIT,
      offset,
    },
  });

  // Reconcile fetched data into the review list
  useEffect(() => {
    if (!reviewData || !reviewData.results) return;
    const reviewResults = reviewData.results;
    const reviewCount = reviewData.count;

    if (offset === 0) {
      setReviews(reviewResults || []);
      previousReviewCount.current = reviewData?.count;
      return;
    }

    if (previousReviewCount.current < reviewCount) {
      setOffset(0);
      return;
    }

    setReviews((previousReviews) => {
      const newReviews = reviewResults.filter(
        (review) => !previousReviews.some((prev) => prev.id === review.id),
      );

      return [...previousReviews, ...newReviews];
    });
  }, [reviewData]);

  function handleSearch(searchValue: string) {
    setFilters((prevState) => ({...prevState, search: [searchValue]}));
  }

  const loadMoreReviews = useCallback(() => {
    if (!reviewData?.next) return;

    // Extract offset number from URL
    const url = new URL(reviewData.next);
    const newOffset = parseInt(url.searchParams.get('offset') || '0');
    setOffset(newOffset);
  }, [reviewData]);

  useInfiniteScrollObserver({
    targetRef: loadMoreRef,
    onIntersect: loadMoreReviews,
    enabled: reviews.length > 0 && !!reviewData?.next && !isFetching,
  });

  return (
    <ComptLoadingIndicator isLoading={sessionQuery.isLoading || companyQuery.isLoading}>
      <div className="flex flex-col">
        <div className="flex flex-col h-full mb-24">
          <div>
            <>
              <div className="flex items-center justify-between mb-6">
                <p className="heading1">Activity feed</p>
                <div className=" w-[320px]">
                  <SearchFilter
                    label="community feed search filter"
                    handleChange={handleSearch}
                    currentValues={filters.search}
                    options={[
                      {
                        id: 1,
                        name: 'Community Feed Search',
                        placeholder: 'Search keywords',
                      },
                    ]}
                  />
                </div>
              </div>
              {reviews.length > 0 &&
                reviews.map((review, i) => (
                  <div className="mb-400" key={`${review.id}-${i}`}>
                    <ComptCommunityCard review={review} />
                  </div>
                ))}{' '}
            </>
            <div ref={loadMoreRef}>
              {(isLoading || isFetching) && (
                <div className="flex flex-col w-full m-6 h-full mt-40">
                  <div className="mb-[9px]">
                    <ComptLoadingAnimation />
                  </div>
                  <p className="label3 text-color-body1 -mt-7 mx-auto">Loading more reviews...</p>
                </div>
              )}
            </div>
            {!(isLoading || isFetching) && reviews.length === 0 && (
              <div className="flex flex-col items-center h-full mt-40">
                <ComptSvgIcon iconName="empty-img-icon" className="mb-6" />
                <h4 className="text-center">No course reviews yet.</h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </ComptLoadingIndicator>
  );
};
