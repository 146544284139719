import React, {Dispatch, Fragment, SetStateAction, useEffect} from 'react';

// Hooks and methods
import {FieldValues, useForm} from 'react-hook-form';

// Components
import {
  ComptButton,
  ComptButtonIcon,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {Dialog, Transition} from '@headlessui/react';
import {ComptTextAreaField} from '@compt/common/forms/compt-text-area-field/compt-text-area-field';

// Types
import {DEFAULT_CHAR_FIELD_MAX_LENGTH} from '@compt/constants';
import {PreApprovalRequest} from '@compt/types/learning-development/pre-approval-request';
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';

interface RejectPreApprovalRequestModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  requestToReject: PreApprovalRequest | undefined;
  isUpdating: boolean;
  handleRequestStatusUpdate: (
    request: PreApprovalRequest,
    action: LearningRequestStatus,
    rejectionReason: string,
  ) => void;
}

interface RejectionReasonForm extends FieldValues {
  rejection_reason: string;
}

export const RejectPreApprovalRequestModal = (props: RejectPreApprovalRequestModalProps) => {
  const {requestToReject, isOpen, setIsOpen, isUpdating} = props;

  const formMethods = useForm<RejectionReasonForm>();
  const watchedRejectionReason = formMethods.watch('rejection_reason');

  // Reset form when it is closed
  useEffect(() => {
    if (isOpen) return;
    formMethods.resetField('rejection_reason');
  }, [isOpen]);

  function onSubmitRejectionReason(form: RejectionReasonForm) {
    if (!requestToReject) return;

    props.handleRequestStatusUpdate(
      requestToReject,
      LearningRequestStatus.PRE_APPROVAL_REJECTED,
      form.rejection_reason,
    );
  }

  return (
    <>
      <Transition.Root
        show={isOpen}
        as={Fragment}
        data-testid={`rejection-modal-for-${requestToReject?.id}`}
      >
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 backdrop-blur-sm bg-opacity-75 bg-white transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className={`
            flex min-h-full items-end justify-center p-6 text-center sm:items-center sm:p-0
          `}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={`
                relative transform bg-white
                overflow-hidden rounded-xl
                backdrop:blur-sm px-4 pb-4 pt-5
                text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 border-2
              `}
                >
                  <div className="compt-modal flex">
                    <div className="w-full">
                      <div className="flex justify-between mb-8 ">
                        <div className="pr-4">
                          <ComptSvgIcon iconName="red-circle-trash-icon" />
                        </div>
                        <div className="flex flex-col space-y-2">
                          <p className="body1">Reject request</p>
                          <p className="body3 text-color-body2">
                            Please give a reason for rejecting this request.
                          </p>
                        </div>
                        <button className="flex pl-2 h-0" onClick={() => setIsOpen(false)}>
                          <ComptSvgIcon iconName={ComptButtonIcon.X} />
                        </button>
                      </div>
                      <ComptTextAreaField
                        name="rejection_reason"
                        control={formMethods.control}
                        register={formMethods.register}
                        validation={{
                          required: 'Rejection reason is required',
                          maxLength: {
                            value: DEFAULT_CHAR_FIELD_MAX_LENGTH,
                            message: `Your rejection reason must be under
                             ${DEFAULT_CHAR_FIELD_MAX_LENGTH} characters.`,
                          },
                        }}
                        errors={formMethods.formState.errors.rejection_reason}
                        data-testid="rejection-reason-field"
                        maxLength={DEFAULT_CHAR_FIELD_MAX_LENGTH}
                        watchedValue={watchedRejectionReason}
                      />
                      <div className="flex justify-end">
                        <ComptButton
                          buttonType={ComptButtonType.OUTLINED}
                          disabled={isUpdating}
                          onClick={() => setIsOpen(false)}
                        >
                          Cancel
                        </ComptButton>
                        <ComptButton
                          buttonType={ComptButtonType.DESTRUCTIVE}
                          disabled={isUpdating}
                          onClick={formMethods.handleSubmit(onSubmitRejectionReason)}
                          data-testid={`admin-lnd-reject-button-modal-${requestToReject?.id}`}
                        >
                          Reject
                        </ComptButton>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
