import React, {Dispatch, SetStateAction} from 'react';

// Types
import {INDUSTRY_OPTIONS} from '@compt/types/industry';

// Components
import {
  ComptButton,
  ComptButtonSize,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {SidebarCollapsible} from '@compt/common/compt-sidebar-dropdown/compt-sidebar-collapsible';

type FiltersState = Record<string, string[]>;

interface CommunityFeedSidebarProps {
  filters: Record<string, string[]>;
  setFilters: Dispatch<SetStateAction<Record<string, string[]>>>;
}

export const CommunityFeedSidebar = (props: CommunityFeedSidebarProps) => {
  const {filters, setFilters} = props;

  function handleFilterSelection<T extends FiltersState>(
    filterKey: keyof T,
    optionId: string,
    isChecked: boolean,
    setFilters: (updater: (prevState: T) => T) => void,
  ) {
    setFilters((prevState) => {
      const currentFilterValues = prevState[filterKey];

      const updatedFilterValues = isChecked
        ? [...currentFilterValues, optionId]
        : currentFilterValues.filter((id: string) => id !== optionId);

      return {...prevState, [filterKey]: updatedFilterValues};
    });
  }

  function clearAllFilters<T extends FiltersState>(
    setFilters: (updater: (prevState: T) => T) => void,
  ) {
    setFilters((prevState) => {
      const {search, ...filtersExcludingSearch} = prevState;
      const clearedFilters = Object.keys(filtersExcludingSearch).reduce((acc, key) => {
        (acc as FiltersState)[key] = [];
        return acc;
      }, {} as T);

      return {...clearedFilters, search};
    });
  }

  function showClearAllButton<T extends FiltersState>(filters: T): boolean {
    const {search, ...filtersExcludingSearch} = filters;
    return Object.values(filtersExcludingSearch).some((values) => values.length > 0);
  }

  return (
    <div className="flex flex-col p-4 flex-grow">
      <div className="flex justify-between items-center">
        <p className="text-md text-color-body1 font-bold">Filter</p>
        {showClearAllButton(filters) && (
          <ComptButton
            buttonType={ComptButtonType.OUTLINED}
            onClick={() => clearAllFilters(setFilters)}
            size={ComptButtonSize.SMALL}
            className="py-1 px-0 min-w-[70px]"
          >
            Clear all
          </ComptButton>
        )}
      </div>
      <div className="flex flex-col mt-4 space-y-2">
        <SidebarCollapsible
          collapsibleId="community-feed-industry"
          label="Industry"
          options={INDUSTRY_OPTIONS as {id: string; label: string}[]}
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.label}
          selectedOptions={filters?.industry}
          handleSelectionChange={(id, checked) =>
            handleFilterSelection('industry', id, checked, setFilters)
          }
        />
      </div>
      <hr className="my-4 mr-4 border-gray-300" />
      <label key="my-company-only-community-feed" className="flex items-center space-x-1">
        <input
          type="checkbox"
          className={`h-4 w-4 rounded border-gray-300 text-color-link checked:bg-base-500
              focus:ring-base-500 cursor-pointer mr-100 disabled:bg-gray-400 
              disabled:hover:bg-gray-400`}
          checked={filters?.company?.includes('true')}
          onChange={(e) => handleFilterSelection('company', 'true', e.target.checked, setFilters)}
        />
        <span className="text-md text-gray-700">Only my company</span>
      </label>
    </div>
  );
};
