import React, {useEffect, useMemo, useState} from 'react';

// RTK queries
import {
  useCreateEmployeeReimbursementRequestMutation,
  useDeleteEmployeePreApprovalRequestMutation,
  useUpdateEmployeePreApprovalRequestMutation,
} from '@compt/app/services/api/employee-learning-development-slice';

// Hooks and methods
import {twMerge} from 'tailwind-merge';
import {useForm} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import {formatCurrency} from '@compt/utils/international-helpers';
import {triggerCustomToast} from '../compt-toaster/compt-toaster';
import {DATE_FORMAT_OPTION, formattedDate, isExpiredDate} from '@compt/utils/date-helpers';
import {useConfirmationModal} from '@compt/utils/confirmation-modal-helper';

// Types
import {DateString} from '@compt/types/common/date-string';
import {EmployeeReimbursementRequestPayload} from '@compt/types/learning-development/reimbursement-request';
import {
  EmployeePreApprovalRequest,
  PreApprovalRequest,
} from '@compt/types/learning-development/pre-approval-request';
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';
import {RequestType} from '@compt/types/learning-development/learning-development-program';
import {Company} from '@compt/types/company';
import {ExpenseStatus} from '@compt/types/stipend-expense';
import {ModalType} from '../compt-confirmation-modal/compt-confirmation.modal';

// Components
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';
import {
  RequestFormFieldValues,
  RequestFormSidePanel,
} from '@compt/pages/learning-page/components/request-form-side-panel/request-form-side-panel';
import {ReviewFormSidePanel} from '@compt/pages/learning-page/components/review-forms/review-form-side-panel';

interface ComptReimbursementCardProps {
  request: EmployeePreApprovalRequest;
  company?: Company;
  currency?: string;
  onUpdateRequest?: (updatedRequest: PreApprovalRequest) => void;
}

interface StepType {
  label: string;
  key: LearningRequestStatus;
  date: string;
}

// TODO(COMPT-5758) eventually this should not be the default.
// Professional Development
const DEFAULT_CATEGORY_ID = 4;

export const getReimbursementStatus = (
  request: PreApprovalRequest,
): LearningRequestStatus | null => {
  if (!request?.expense) {
    return null;
  }

  if ([ExpenseStatus.Open, ExpenseStatus.Approved].includes(request.expense.status)) {
    return LearningRequestStatus.REIMBURSEMENT_REQUESTED;
  }

  if (request.expense.status === ExpenseStatus.Approved) {
    return LearningRequestStatus.REIMBURSEMENT_APPROVED;
  }

  if (request.expense.status === ExpenseStatus.Processed) {
    return LearningRequestStatus.REIMBURSED;
  }

  if (request.expense.status === ExpenseStatus.Rejected) {
    return LearningRequestStatus.REIMBURSEMENT_REJECTED;
  }

  return null;
};

export const ComptReimbursementCard = (props: ComptReimbursementCardProps) => {
  const {request, company, onUpdateRequest} = props;
  const [openRequestPanel, setOpenRequestPanel] = useState(false);
  const [openReimbursementRequestPanel, setOpenReimbursementRequestPanel] = useState(false);
  const [openReviewPanel, setOpenReviewPanel] = useState(false);

  const formMethods = useForm<RequestFormFieldValues>();
  const [, setSearchParams] = useSearchParams();

  const [updatePreApprovalRequest, {isLoading}] = useUpdateEmployeePreApprovalRequestMutation();
  const [createReimbursementRequest, createReimbursementRequestStatus] =
    useCreateEmployeeReimbursementRequestMutation();
  const [deletePreApprovalRequest] = useDeleteEmployeePreApprovalRequestMutation();

  const {modal: confirmDeleteModal, showModal: showDeleteModal} = useConfirmationModal(
    // eslint-disable-next-line max-len
    'Are you sure you want to delete this pre-approval request? This action cannot be undone.',
    'Confirm request deletion',
    ModalType.DESTRUCTIVE,
    'Delete',
  );

  useEffect(() => {
    if (!request) return;

    formMethods.reset({
      receipt_key: request.request_data['Upload document(s)'],
      description: request.request_data['Description and reason'],
      vendor: request.request_data['Vendor name'],
      amount: request.request_data.Amount,
      expected_completion_date: request.request_data['Expected completion date'],
    });

    formMethods.reset({
      amount: request.request_data.Amount,
      vendor: request.request_data['Vendor name'],
      receipt_key: '',
    });
  }, [request]);

  const getStepStatuses = (requestStatus: LearningRequestStatus) => {
    const steps: StepType[] = [
      {
        label: 'Approved',
        key: LearningRequestStatus.PRE_APPROVED,
        date: formattedDate(props.request?.reviewed_on, DATE_FORMAT_OPTION['month dd yyyy']),
      },
      {
        label: 'Submitted',
        key: LearningRequestStatus.REIMBURSEMENT_REQUESTED,
        date: formattedDate(
          props.request?.expense?.created_on,
          DATE_FORMAT_OPTION['month dd yyyy'],
        ),
      },
    ];

    steps.unshift({
      label: 'Requested',
      key:
        requestStatus === LearningRequestStatus.PARTIALLY_APPROVED
          ? requestStatus
          : LearningRequestStatus.PRE_APPROVAL_REQUESTED,
      date: formattedDate(props.request?.requested_on, DATE_FORMAT_OPTION['month dd yyyy']),
    });

    if (requestStatus === LearningRequestStatus.PRE_APPROVAL_REJECTED) {
      steps.push({
        label: 'Rejected',
        key: LearningRequestStatus.PRE_APPROVAL_REJECTED,
        date: formattedDate(props?.request.reviewed_on, DATE_FORMAT_OPTION['month dd yyyy']),
      });
    } else if (requestStatus === LearningRequestStatus.REIMBURSEMENT_REJECTED) {
      steps.push({
        label: 'Rejected',
        key: LearningRequestStatus.REIMBURSEMENT_REJECTED,
        date: formattedDate(
          props.request.reimbursement_request?.reviewed_on,
          DATE_FORMAT_OPTION['month dd yyyy'],
        ),
      });
    } else {
      steps.push({
        label: 'Reimbursed',
        key: LearningRequestStatus.REIMBURSED,
        date: formattedDate(
          props?.request?.expense?.closed_on,
          DATE_FORMAT_OPTION['month dd yyyy'],
        ),
      });
    }

    return steps.map((step, index) => {
      const isComplete =
        (requestStatus === LearningRequestStatus.PRE_APPROVED && index <= 1) ||
        (reimbursementStatus === LearningRequestStatus.REIMBURSEMENT_REQUESTED && index <= 2) ||
        reimbursementStatus === LearningRequestStatus.REIMBURSED ||
        requestStatus === step.key;

      return {
        ...step,
        isComplete,
      };
    });
  };

  function onUpdatePreApprovalSubmit(form: RequestFormFieldValues) {
    if (!company?.id || !request || !form['Expected completion date']) {
      triggerCustomToast('error', 'An error occurred updating a pre-approval request');
      return;
    }

    const {receipt_key, ...restOfFields} = form;

    const submission = {
      program_id: request.program_id,
      status: LearningRequestStatus.PRE_APPROVAL_REQUESTED,
      request_data: {
        ...restOfFields,
        'Upload document(s)': receipt_key,
        'Expected completion date': form['Expected completion date'],
      },
    };

    updatePreApprovalRequest({body: submission, companyId: company.id, requestId: request.id}).then(
      (results) => {
        if ('error' in results) {
          triggerCustomToast('error', 'An error occurred updating a pre-approval request');
          return;
        }

        if (onUpdateRequest) {
          onUpdateRequest(results.data);
        }

        triggerCustomToast('success', 'Successfully updated a pre-approval request!');
        setOpenRequestPanel(false);
        formMethods.reset();
        formMethods.reset({'Expected completion date': undefined});
      },
    );
  }

  function onCreateReimbursementRequestSubmit(form: RequestFormFieldValues) {
    if (!company?.id || !request || !form['Expected completion date']) {
      triggerCustomToast('error', 'An error occurred submitting reimbursement request');
      return;
    }

    const requestData = {
      'Vendor name': form['Vendor name'],
      Amount: form['Amount'],
      'Receipt date': form['Expected completion date'],
      'Description and reason': form['Description and reason'],
      'Upload document(s)': form.receipt_key,

      Category: DEFAULT_CATEGORY_ID,
    };

    const submission: EmployeeReimbursementRequestPayload = {
      program_id: request.program_id,
      pre_approval_request_id: request.id,
      status: 'PENDING_APPROVAL', // Non-LearningRequestStatus required to send for expense object
      request_data: {
        ...requestData,
      },
    };

    createReimbursementRequest({
      body: submission,
      companyId: company.id,
    }).then((results) => {
      if ('error' in results) {
        triggerCustomToast('error', 'An error occurred submitting reimbursement request');
        return;
      }

      if (onUpdateRequest) {
        const newRequest: PreApprovalRequest = {
          ...request,
          reimbursement_request: results.data,
          expense: results.data?.expense,
        };

        onUpdateRequest(newRequest);
      }

      setOpenReimbursementRequestPanel(false);
      formMethods.reset();
      formMethods.reset({'Expected completion date': undefined});

      setOpenReviewPanel(true);
    });
  }

  async function onRequestDelete() {
    if (!company?.id || !request) {
      triggerCustomToast('error', 'An error occurred deleting pre-approval request');
      return;
    }

    if (request.status !== LearningRequestStatus.PRE_APPROVAL_REQUESTED) {
      triggerCustomToast(
        'error',
        'This pre-approval request is already in review and unable to be deleted',
      );
      return;
    }

    const confirmDelete = await showDeleteModal();
    if (!confirmDelete) return;

    deletePreApprovalRequest({companyId: company.id, requestId: request.id}).then((results) => {
      if ('error' in results) {
        triggerCustomToast('error', 'An error occurred deleting pre-approval request');
        return;
      }

      triggerCustomToast('success', 'Successfully deleted your pre-approval request');
      setOpenRequestPanel(false);
    });
  }

  const {isExpired, targetDate} = isExpiredDate(request.allotment?.expiration_date);

  const renderReimbursementButton = () => {
    if (props.request.status === LearningRequestStatus.PRE_APPROVED) {
      if (!props.request.expense && !isExpired) {
        return (
          <button
            className="label3 mr-4 text-color-link"
            onClick={() => setOpenReimbursementRequestPanel(true)}
          >
            Request reimbursement
          </button>
        );
      }

      if (props?.request?.expense?.status === ExpenseStatus.Open) {
        // TODO(COMPT-5757) - Need to implement support for updates
        // return (
        //   <button
        //     className="label3 mr-4 text-color-link"
        //     onClick={() => setOpenReimbursementRequestPanel(true)}
        //   >
        //     Edit Claim
        //   </button>
        // );
      }
    }
    return null;
  };
  const reimbursementStatus = useMemo(() => getReimbursementStatus(request), [request]);
  const steps = useMemo(() => getStepStatuses(request.status), [request]);

  return (
    <>
      {confirmDeleteModal}
      <ReviewFormSidePanel
        open={openReviewPanel}
        setOpen={setOpenReviewPanel}
        request={props.request}
        companyId={props.company?.industry}
      />
      <RequestFormSidePanel
        id="lnd-request-form"
        title="Request form"
        subtitle={props.request.program.name}
        open={openRequestPanel}
        requestType={RequestType.PRE_APPROVAL}
        mutationLoading={isLoading}
        onSubmit={onUpdatePreApprovalSubmit}
        formMethods={formMethods}
        setOpen={setOpenRequestPanel}
        program={props.request.program}
        preApprovalRequest={props.request}
        onRequestDelete={onRequestDelete}
        programCurrency={props.currency}
        requestFields={props.request.pre_approval_configuration.request_fields}
      />
      <RequestFormSidePanel
        id="lnd-request-form"
        title="Request reimbursement"
        subtitle={props.request.program.name}
        open={openReimbursementRequestPanel}
        requestType={RequestType.REIMBURSEMENT}
        mutationLoading={createReimbursementRequestStatus.isLoading}
        onSubmit={onCreateReimbursementRequestSubmit}
        formMethods={formMethods}
        setOpen={setOpenReimbursementRequestPanel}
        program={props.request.program}
        preApprovalRequest={props.request}
        programCurrency={props.currency}
        maxAmount={props.request.request_data.Amount}
        requestFields={props.request.pre_approval_configuration.request_fields.filter(
          (field) => field.is_default,
        )}
      />
      <div className="flex flex-col rounded-3xl border border-stroke-divider-2 p-400">
        <div className="flex justify-between">
          <div className="flex">
            <p className="label2 text-color-heading mr-1">
              {props.request.request_data['Vendor name']}
            </p>
            <p className="label2 text-color-heading">• {props.request.program.name}</p>
          </div>
          <p className="label2 text-color-heading">
            {formatCurrency(props.request.request_data.Amount, props.request.currency)}
          </p>
        </div>

        <p className="body3 text-color-body1 mt-2">
          {props.request.request_data['Description and reason']}
        </p>

        <div className="flex justify-center w-full py-400">
          {steps.map((step, index) => (
            <Step
              key={index}
              status={reimbursementStatus || props.request.status}
              label={step.label}
              date={step.date}
              isComplete={step.isComplete}
              nextStepComplete={steps[index + 1]?.isComplete}
              isFirstStep={index === 0}
              isLastStep={index === steps.length - 1}
            />
          ))}
        </div>
        <div className="flex justify-between">
          <div className="flex cursor-pointer">
            {props.request.status === LearningRequestStatus.PRE_APPROVAL_REQUESTED && (
              <button
                className="label3 mr-4 text-color-link cursor-pointer"
                onClick={() => setOpenRequestPanel(true)}
              >
                Edit request
              </button>
            )}
            {renderReimbursementButton()}
            <button
              className="flex flex-row items-center cursor-pointer"
              onClick={() => setSearchParams({pre_approval_id: `${props.request.id}`})}
            >
              <p className="label3 text-color-body1 cursor-pointer">View details</p>
              <ComptSvgIcon
                iconName="chevron-right-icon-gray-700"
                svgProp={{width: '18px', height: '18px'}}
              />
            </button>
          </div>
          {request?.allotment &&
            (request.status === LearningRequestStatus.PRE_APPROVAL_REQUESTED ||
              request.status === LearningRequestStatus.PRE_APPROVED ||
              request.status === LearningRequestStatus.PARTIALLY_APPROVED) && (
              <div className="flex items-center">
                <ComptSvgIcon iconName="ellipse-circle-icon" />
                <p className="label4 text-gray-700 ml-2">
                  {`Expire${isExpired ? 'd' : 's'} on ${targetDate}`}
                </p>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

interface StepProps {
  label: string;
  date?: DateString;
  isComplete: boolean;
  nextStepComplete: boolean;
  status: LearningRequestStatus;
  isFirstStep: boolean;
  isLastStep: boolean;
}

const Step = (props: StepProps) => {
  function getLineColor(status: LearningRequestStatus, isComplete: boolean) {
    if (
      status === LearningRequestStatus.REIMBURSEMENT_REJECTED ||
      status === LearningRequestStatus.PRE_APPROVAL_REJECTED
    )
      return 'bg-gray-400';
    if (status === LearningRequestStatus.REIMBURSED) return 'bg-green-500';
    if (isComplete) return 'bg-brand-700';
    return 'bg-gray-300';
  }

  function getLineAfterColor(status: LearningRequestStatus, nextStepComplete: boolean) {
    if (
      status === LearningRequestStatus.REIMBURSEMENT_REJECTED ||
      status === LearningRequestStatus.PRE_APPROVAL_REJECTED
    )
      return 'bg-gray-400';
    if (status === LearningRequestStatus.REIMBURSED) return 'bg-green-500';
    if (nextStepComplete) return 'bg-brand-700';
    return 'bg-gray-300';
  }

  function getDotColor(status: LearningRequestStatus, isComplete: boolean, isLastStep: boolean) {
    if (
      status === LearningRequestStatus.REIMBURSEMENT_REJECTED ||
      (status === LearningRequestStatus.PRE_APPROVAL_REJECTED && isLastStep)
    )
      return 'bg-stroke-critical';
    if (status === LearningRequestStatus.REIMBURSED) return 'bg-green-500';
    if (isComplete) return 'bg-brand-700';
    return 'bg-gray-300';
  }

  const lineBeforeColor = getLineColor(props.status, props.isComplete);
  const lineAfterColor = getLineAfterColor(props.status, props.nextStepComplete);

  const dotColor = getDotColor(props.status, props.isComplete, props.isLastStep);

  const Dot = ({className}: {className?: string}) => (
    <div className={twMerge(`w-6 h-3 rounded-full ${dotColor} ${className}`)} />
  );

  const Line = ({className}: {className?: string}) => (
    <div className={twMerge(`grow h-px w-full ${className}`)} />
  );

  const DotLabel = () => (
    <div className="flex flex-col items-center">
      <span className={'label4 text-color-body1 mt-2'}>{props.label}</span>
      {props.date && <span className="text-xs text-gray-500">{props.date}</span>}
    </div>
  );

  return (
    <div className="flex flex-col w-full">
      {props.isFirstStep && (
        <div className="w-full flex flex-col">
          <div className="flex w-full items-center">
            <div className="h-px w-full" />
            <Dot />
            <Line className={lineAfterColor} />
          </div>
          <DotLabel />
        </div>
      )}

      {!props.isFirstStep && !props.isLastStep && (
        <div className="w-full flex flex-col">
          <div className="flex w-full items-center">
            <Line className={lineBeforeColor} />
            <Dot />
            <Line className={lineAfterColor} />
          </div>
          <DotLabel />
        </div>
      )}

      {props.isLastStep && (
        <div className="w-full flex flex-col">
          <div className="flex w-full items-center">
            <Line className={lineAfterColor} />
            <Dot className="items-center" />
            <div className="h-px w-full" />
          </div>
          <DotLabel />
        </div>
      )}
    </div>
  );
};
