import React, {useEffect} from 'react';

// Hooks and methods
import {useForm} from 'react-hook-form';

// Types
import {ReviewFieldValues} from './review-field-values.types';
import {EmployeeReview} from '@compt/types/community-feed';

// Components
import {ReviewForm} from './review-form';

interface ReviewFormTabProps {
  employeeReview: EmployeeReview;
}

export const ReviewFormTab = ({employeeReview}: ReviewFormTabProps) => {
  const formMethods = useForm<ReviewFieldValues>();

  useEffect(() => {
    formMethods.reset({
      describe_purchased: employeeReview.review_title,
      course_url: employeeReview.course_url,
      rating_number: employeeReview.rating,
      rating_detail: employeeReview.review_description,
      keywords: employeeReview.user_entered_keywords,
    });
  }, [employeeReview]);

  return (
    <div className="p-6">
      <ReviewForm formMethods={formMethods} readOnly={true} />
    </div>
  );
};
