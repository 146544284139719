import {BaseIconName} from './compt-side-nav-button';

export enum SideNavItemKeys {
  // Internal Admin Section
  DJANGO_ADMIN = 'DJANGO_ADMIN',
  COMPT_ADMIN = 'COMPT_ADMIN',

  // Employee Section
  MY_STIPEND = 'MY_STIPEND',
  BUSINESS_EXPENSE = 'BUSINESS_EXPENSE',
  TEAM_RECOGNITION = 'TEAM_RECOGNITION',
  ACTIVITY = 'ACTIVITY',
  EXPLORE = 'EXPLORE',
  FAQ = 'FAQ',
  ASSIGNMENTS = 'ASSIGNMENTS',
  LEARNING = 'LEARNING',
  COMMUNITY = 'COMMUNITY',

  // Stipend Admin Section
  STIPEND_ADMIN_DASHBOARD = 'STIPEND_ADMIN_DASHBOARD',
  STIPEND_ADMIN_STIPENDS = 'STIPEND_ADMIN_STIPENDS',
  STIPEND_ADMIN_TEAM = 'STIPEND_ADMIN_TEAM',
  STIPEND_ADMIN_TEAM_SYNC_LOGS = 'STIPEND_ADMIN_TEAM_SYNC_LOGS',
  STIPEND_ADMIN_PAYROLL = 'STIPEND_ADMIN_PAYROLL',
  STIPEND_ADMIN_FAQ = 'STIPEND_ADMIN_FAQ',
  STIPEND_ADMIN_COMPANY_SETTINGS = 'STIPEND_ADMIN_COMPANY_SETTINGS',
  STIPEND_ADMIN_CUSTOMIZABLE_NOTIFICATIONS = 'STIPEND_ADMIN_CUSTOMIZABLE_NOTIFICATIONS',

  // Business Expense Admin Section
  BE_ADMIN_REPORTING = 'BE_ADMIN_REPORTING',
  BE_ADMIN_TEAM = 'BE_ADMIN_TEAM',
  BE_ADMIN_REVIEW_EXPENSES = 'BE_ADMIN_REVIEW_EXPENSES',
  BE_ADMIN_SEARCH_EXPENSES = 'BE_ADMIN_SEARCH_EXPENSES',
  BE_ADMIN_PAYROLL = 'BE_ADMIN_PAYROLL',
  BE_ADMIN_SETTINGS = 'BE_ADMIN_SETTINGS',

  // Learning and Development Admin Section
  LND_ADMIN_REPORTING = 'LND_ADMIN_REPORTING',
}

export interface SideNavItemType {
  key: string;
  title: string;
  iconId?: BaseIconName;
  pagePath: string;
  /**
   * Determines whether this should be a server path for use within an HTML 'a' tag.
   */
  isHref?: boolean;
}
