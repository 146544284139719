import React, {Dispatch, SetStateAction} from 'react';

// RTK queries
import {useCreateLearningReviewMutation} from '@compt/app/services/api/community-feed-slice';

// Hooks and methods
import {useForm} from 'react-hook-form';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

// Types
import {ReviewFieldValues} from './review-field-values.types';
import {PreApprovalRequest} from '@compt/types/learning-development/pre-approval-request';

// Components
import {ReviewForm} from './review-form';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';

interface ReviewFormSidePanelProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  request: PreApprovalRequest;
  companyId: number | undefined;
  'data-testid'?: string;
}

export const ReviewFormSidePanel = (props: ReviewFormSidePanelProps) => {
  const formMethods = useForm<ReviewFieldValues>();

  const [createLearningReview, {isLoading: learningReviewUpdating}] =
    useCreateLearningReviewMutation();

  function onSubmit(form: ReviewFieldValues) {
    if (!props.companyId || !props.request.id) return;

    const submission = {
      request_id: props.request.id,
      review_title: form.describe_purchased,
      course_url: form.course_url,
      review_description: form.rating_detail,
      rating: form.rating_number,
      user_entered_keywords: form.keywords ?? [],
    };

    createLearningReview({companyId: props.companyId, body: submission}).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem submitting this learning review',
          'Please try again',
        );
        return;
      }

      triggerCustomToast('success', 'Successfully submitted learning review');
      formMethods.reset();
      props.setOpen(false);
    });
  }

  function onCancel() {
    if (learningReviewUpdating) return;
    formMethods.reset();
    props.setOpen(false);
  }

  return (
    <ComptSidePanel open={props.open} className="max-w-[520px]" data-testid={props['data-testid']}>
      <ComptSidePanel.Header
        title="Reimbursement form"
        subtitle={props.request.program.name}
        setOpen={onCancel}
        headerIcon={{id: 'file-icon-blue'}}
        breadCrumbs={['Reimbursement request', 'Your review (optional)']}
      />
      <ComptSidePanel.Content className="sm:overflow-y-auto p-6">
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <p className="label2 text-color-heading">Your review</p>
          <p className="body3 text-color-body2 mb-6">
            Review your experience with the purchase. This will be shared publicly on the Community
            page. Responses are anonymous.
          </p>
          <ReviewForm formMethods={formMethods} />
          {/* TODO: uncomment in COMPT-6136 */}
          {/* <p className="label2 text-color-heading">Show your LinkedIn profile</p>
          <p className="body3 text-color-body2 mb-6">
            Add your LinkedIn URL if you would like your profile to be visible to other Compt users
            (across companies) on the Community feed.
          </p>
          <ComptTextField name="linkedin_url" label="LinkedIn URL" placeholder="https://" />
          <p className="body3 text-color-body2 -mt-3">
            By adding your LinkedIn URL, your review will no longer be anonymous.
          </p> */}
        </form>
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        <div className="flex">
          <ComptButton
            type="submit"
            onClick={formMethods.handleSubmit(onSubmit)}
            disabled={learningReviewUpdating}
          >
            Submit
          </ComptButton>
          <ComptButton
            buttonType={ComptButtonType.BORDERLESS}
            onClick={onCancel}
            disabled={learningReviewUpdating}
          >
            Skip
          </ComptButton>
        </div>
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};
