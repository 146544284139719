import {
  BudgetDistributionOption,
  EligibilityBreakdownOption,
  // eslint-disable-next-line max-len
} from '@compt/types/learning-development/eligibility-breakdown.types';
import {DateString} from '../common/date-string';
import {ListResultsBase} from '../common/list-results';
import {Company} from '../company';
import {PerkCategory} from '../perk-category';

export enum FundsExpirationStrategyType {
  AFTER_APPROVAL = 'AFTER_APPROVAL',
  AFTER_COMPLETION_DATE = 'AFTER_COMPLETION_DATE',
}

export enum RequestFieldType {
  SHORT_TEXT = 'SHORT_TEXT',
  LONG_TEXT = 'LONG_TEXT',
  DATE = 'DATE',
  CURRENCY = 'CURRENCY',
  FILE = 'FILE',
  PERK_CATEGORY_ID = 'PERK_CATEGORY_ID',
}
export enum RequestType {
  PRE_APPROVAL = 'PRE_APPROVAL',
  REIMBURSEMENT = 'REIMBURSEMENT',
}

export interface RequestField {
  id: number;
  field_name: string;
  supporting_text: string | null | undefined;
  sequence: number;
  field_type: RequestFieldType;
  is_active: boolean;
  is_default: boolean;
  is_editable: boolean;
  is_required: boolean;
}

export interface RequestConfiguration {
  id: number;
  company: Company['id'];
  program: number;
  request_fields: RequestField[];
}

export interface ReviewingUser {
  id: number;
  full_name: string;
  first_name?: string;
  last_name?: string;
  email: string;
}

export type ReviewingUserListResults = ListResultsBase<ReviewingUser>;

export interface ReviewingUserPayload {
  user_id: number;
  program_reviewing_configuration: number;
}

export interface ProgramReviewingConfiguration {
  id: number;
  company: {
    id: Company['id'];
  };
  program: {
    id: LearningDevelopmentProgram['id'];
  };
  created_on: DateString;
  modified_on: DateString;
  created_by: number;
  last_modified_by: number;
  primary_approvers: ReviewingUser[];
  use_employee_manager_as_primary_approver: boolean;
  secondary_approvers: ReviewingUser[];
  use_employee_manager_as_secondary_approver: boolean;
  reimbursement_reviewers: ReviewingUser[];
  use_employee_manager_as_reimbursement_reviewer: boolean;
}

export interface ProgramReviewingConfigurationPayload {
  company: {
    id: Company['id'];
  };
  program: {
    id: LearningDevelopmentProgram['id'];
  };
  use_employee_manager_as_primary_approver?: boolean;
  use_employee_manager_as_secondary_approver?: boolean;
  use_employee_manager_as_reimbursement_reviewer?: boolean;
}

export interface LearningDevelopmentProgram {
  id: number;
  company: {
    id: Company['id'];
    name: Company['name'];
  };
  name: string;
  description: string;
  eligibility: EligibilityBreakdownOption;
  budget_distribution: BudgetDistributionOption | null;
  budget_renewal_months: number | null;
  funds_expiration_days: number;
  funds_expiration_strategy: FundsExpirationStrategyType;
  start_date: DateString;
  termination_date: DateString;
  is_draft: boolean;
  perk_categories: PerkCategory[];
  pre_approval_configuration: RequestConfiguration;
  reimbursement_configuration: RequestConfiguration;
  program_funding_rules: ProgramFundingRule[];
  program_reviewing_configuration: ProgramReviewingConfiguration;
  created_on: DateString;
  modified_on: DateString;
  created_by: number;
  last_modified_by: number;
}

export type LearningDevelopmentProgramListResults = ListResultsBase<LearningDevelopmentProgram>;

export interface LearningDevelopmentProgramPayload {
  name: LearningDevelopmentProgram['name'];
  description: LearningDevelopmentProgram['description'];
  eligibility: LearningDevelopmentProgram['eligibility'];
  budget_renewal_months: LearningDevelopmentProgram['budget_renewal_months'];
  is_draft: boolean;
  budget_distribution: LearningDevelopmentProgram['budget_distribution'];
}

export interface ProgramFundingRule {
  id: number;
  company: Company['id'];
  program: LearningDevelopmentProgram['id'];
  user_grouping_rule: {
    user_properties: {
      [key: string]: string;
    };
  };
  name: string;
  amount: string;
  currency: string;
  created_on: DateString;
  modified_on: DateString;
  created_by: number;
  last_modified_by: number;
  sequence: number;
}

export interface ProgramFundingRulePayload {
  amount: string | number;
  currency: string;
  user_grouping_rule:
    | {
        user_properties: {
          [key: string]: string;
        };
      }
    | Record<string, never>;
  sequence: number;
}
export interface LearningDevelopmentProgramBudget {
  user: {
    id: number;
  };
  program: {
    id: LearningDevelopmentProgram['id'];
  };
  program_funding_rule: [];
  amount: number;
  amount_committed?: number;
  amount_reimbursed?: number;
  expiration_datetime?: DateString;
}
