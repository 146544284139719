import {DateString} from '../common/date-string';
import {ListResultsBase} from '../common/list-results';
import {EmployeeReview} from '../community-feed';
import {RequestConfiguration, ReviewingUser} from './learning-development-program';
import {LearningRequestStatus} from './learning-request-status';
import {Allotment} from '@compt/types/allotments';
import {
  LeanExpense,
  LeanProgramBudget,
  ProgramSummary,
} from '@compt/types/learning-development/common-types';
import {ReimbursementRequest} from '@compt/types/learning-development/reimbursement-request';

interface PreApprovalUser {
  id: number;
  full_name: string;
  first_name: string;
  last_name: string;
  email: string;
}

/**
 * The backend generates a dynamic key based on the field name to store the full, signed URL, to access files.
 *
 * @param fieldName the original name of the field (e.g. "Upload document(s)")
 */
export const getCustomFieldURLKey = (fieldName: string): string => `${fieldName}_url`;

// TODO: make this fit for custom data in COMPT-5697
interface PreApprovalRequestData {
  Amount: number;
  ['Vendor name']: string;
  ['Upload document(s)']: string;
  ['Description and reason']: string;
  ['Expected completion date']: string;
  /**
   * This field is needed to support dynamically generated names, including urls keys like "Upload document(s)_url".
   */
  [key: string]: string | number;
}

export interface PreApprovalRequest {
  id: number;
  user: PreApprovalUser;
  status: LearningRequestStatus;
  request_data: PreApprovalRequestData;
  program: ProgramSummary;
  program_budget: LeanProgramBudget;
  program_id: number;
  pre_approval_configuration: RequestConfiguration;
  requested_on: DateString;
  rejection_reason?: string;
  rejected_by: ReviewingUser | null;
  primary_approver: ReviewingUser | null;
  secondary_approver: ReviewingUser | null;
  reviewed_on: DateString | null;
  approved_by_primary_approver_on: DateString | null;
  approved_by_secondary_approver_on: DateString | null;
  modified_on: DateString;
  created_on: DateString;
  allotment?: Allotment;
  expense?: LeanExpense;
  employee_review?: EmployeeReview;
  reimbursement_request?: ReimbursementRequest;
  currency: string;
  comment_channel_id: number;
}

export type PreApprovalRequestListResults = ListResultsBase<PreApprovalRequest>;

// Separate in case employee pre-approval request type diverges from review pre-approval requests
export type EmployeePreApprovalRequest = PreApprovalRequest;
export type EmployeePreApprovalRequestListResults = ListResultsBase<EmployeePreApprovalRequest>;

export interface EmployeePreApprovalRequestPayload {
  program_id: number;
  status: LearningRequestStatus;
  request_data: PreApprovalRequestData;
}
