import React, {useState, useMemo, useEffect} from 'react';

// RTK queries
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetAllAdminPreApprovalRequestsQuery} from '@compt/app/services/api/learning-development-slice';

// Hooks and methods
import {ReviewLnDTableController as controller} from '../review-lnd-table.controller';
import {useSearchParams} from 'react-router-dom';
import {useComptTableContext} from '@compt/common/compt-table/compt-table.context';
import {useDebounce} from '@uidotdev/usehooks';

// Components
import {ComptTable} from '@compt/common/compt-table/compt-table.container';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {ReviewRequestSidePanel} from './review-lnd-request-side-panel';

// Types
import {PreApprovalRequest} from '@compt/types/learning-development/pre-approval-request';
import {FormattedFilterObject} from '@compt/common/compt-filter-bar/compt-filter-bar.types';
import {MAX_15_PAGE_LIMIT} from '@compt/constants';

interface ReviewLnDTableProps {
  selectedStatuses: string[];
}

export const ReviewLnDTable: React.FC<ReviewLnDTableProps> = ({selectedStatuses}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const requestId = searchParams.get('request_id');

  const [isEditingMode, setIsEditingMode] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
  const {setCurrentPage} = useComptTableContext();

  const initialFilterValues = controller.getInitialFilterValues();
  const filterConfiguration = controller.getFilterConfiguration();
  const [queryParams, setQueryParams] = useState<FormattedFilterObject | undefined>({
    limit: MAX_15_PAGE_LIMIT,
    offset: 0,
  });

  const debouncedFilterValues = useDebounce(queryParams, 300);

  const session = useGetSessionQuery();
  const company = useGetCompanyQuery(session.data?.user_id ?? skipToken);

  const preApprovalRequests = useGetAllAdminPreApprovalRequestsQuery(
    {companyId: company.data?.id, params: {...debouncedFilterValues}},
    {skip: !company.data?.id || !debouncedFilterValues?.page},
  );

  const preApprovalRequestIdList = preApprovalRequests.data?.results.map((request) => request.id);
  const totalCount = preApprovalRequests.data?.count ?? 0;

  useEffect(() => {
    if (selectedStatuses.length === 0) {
      setCurrentPage(1);
    }

    setQueryParams((prevParams) => {
      const updatedParams = {...prevParams};
      if (selectedStatuses.length > 0) {
        updatedParams['status__in'] = selectedStatuses?.map((status) => status).join(',');
        updatedParams.include_reviewed = 'true';
        updatedParams.offset = 0;
        setCurrentPage(1);
        setFiltersApplied(true);
      } else {
        delete updatedParams['status__in'];
        delete updatedParams.include_reviewed;
        updatedParams.offset = 0;
        setFiltersApplied(false);
      }
      return updatedParams;
    });
  }, [selectedStatuses, setCurrentPage]);

  const columnDefinition = controller.getColumnDefinition(onActionMenuClicked);

  function onActionMenuClicked(reviewData: PreApprovalRequest, action: 'VIEW' | 'EDIT' | 'DELETE') {
    switch (action) {
      case 'VIEW':
        setSearchParams({request_id: `${reviewData.id}`});
        setIsEditingMode(false);
        break;
      case 'EDIT':
        setSearchParams({request_id: `${reviewData.id}`});
        setIsEditingMode(true);
        break;
      case 'DELETE':
        break;
    }
  }

  const noDataTitleText = useMemo(
    () =>
      filtersApplied
        ? 'No requests were found with the current filters.'
        : 'No requests are ready for your review.',
    [filtersApplied],
  );

  return (
    <ComptLoadingIndicator
      isLoading={company.isLoading || preApprovalRequests.isLoading}
      className="pt-72"
    >
      <ReviewRequestSidePanel
        requestId={requestId}
        editMode={isEditingMode}
        setEditMode={setIsEditingMode}
        requestIdList={preApprovalRequestIdList}
      />
      {company.data && (
        <ComptTable
          company={company.data}
          tableId="review-lnd-table-v2"
          className="w-full"
          data={preApprovalRequests.data?.results || []}
          dataLoading={
            preApprovalRequests.isLoading ||
            preApprovalRequests.isFetching ||
            !debouncedFilterValues?.page
          }
          totalCount={totalCount}
          itemsPerPage={MAX_15_PAGE_LIMIT}
          allowPagination={true}
          columnDefinition={columnDefinition}
          allowShowHide={false}
          initialFilterValues={initialFilterValues}
          filterConfiguration={filterConfiguration}
          showColumnFilters
          noDataTitleText={noDataTitleText}
          onRowClicked={(row) => setSearchParams({request_id: `${row.id}`})}
          onChangeQueryValues={(filterValues, pagination, ordering) => {
            controller.updateQueryParamsOnValuesChanged(
              company?.data?.id,
              setQueryParams,
              setFiltersApplied,
              pagination,
              filterValues,
              ordering,
            );
          }}
          stickyLastColumn
        />
      )}
    </ComptLoadingIndicator>
  );
};
