import React, {HTMLAttributes, useContext} from 'react';

// Hooks and methods
import {useHandleMobileSideNav, useIsMobileView} from '@compt/utils/mobile-helpers';
import {twMerge} from 'tailwind-merge';

// Components
import {NavLink} from 'react-router-dom';
import {Tooltip} from 'react-tooltip';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';
import {SideNavContext} from './side-nav-context';
import {Transition} from '@headlessui/react';

// Base icon names will be appended with color depending on isActive
export enum BaseIconName {
  HOME = 'home-icon',
  LAYERS = 'layers-icon',
  BAR_CHART = 'bar-chart-icon',
  CHECK_DONE = 'check-done-icon',
  PIE_CHART = 'pie-chart-icon',
  BANK_NOTE = 'bank-note-icon',
  ACTIVITY = 'activity-icon',
  AWARD = 'award-icon',
  COIN_SWAP = 'coin-swap-icon',
  ANNOTATION_QUESTION = 'annotation-question-icon',
  BRIEFCASE = 'briefcase-icon',
  SEARCH_REFRACTION = 'search-refraction-icon',
  SETTINGS = 'settings-icon',
  USERS = 'users-icon',
  WALLET = 'wallet-icon',
  COIN_STACKED = 'coin-stacked-icon',
  GLOBE4 = 'globe4-icon',
  GRADUATION_CAP = 'graduation-cap-icon',
  HOME_SMILE = 'home-smile-icon',
  SEARCH = 'search-md-icon',
}

export interface SideNavButtonProps extends HTMLAttributes<HTMLButtonElement> {
  iconId?: BaseIconName;
  pagePath: string;
  isHref?: boolean;
  excludeIcon?: boolean;
  id?: string;
  onlyIcon?: boolean;
  isSecondaryButton?: boolean;
}

const sideNavClasses = ['rounded-md', 'compt-button', 'justify-start', 'items-center', 'w-full'];

const getClasses = (isActive: boolean, isMobile: boolean, excludeIcon?: boolean) => {
  const classes = [...sideNavClasses];

  isActive ? classes.push('side-nav-active') : classes.push('side-nav-inactive');

  if (isMobile && excludeIcon) {
    classes.push('pr-3 pl-12');
  }

  return classes.join(' ');
};

const getIconIdAndTextColor = (iconId: string, isActive: boolean) => ({
  iconIdColor: isActive ? `${iconId}-bold` : `${iconId}-gray`,
});

export const ComptSideNavButton = (props: SideNavButtonProps) => {
  const {id, iconId, pagePath, isHref, excludeIcon} = props;
  const {sidebarOpen, setSidebarOpen} = useContext(SideNavContext);
  const handleMobileSideNav = useHandleMobileSideNav(setSidebarOpen);
  const isMobileView = useIsMobileView();

  // Handles server side paths that exist outside of React Router
  if (isHref) {
    const isActive = window.location.pathname === pagePath;
    const {iconIdColor} = getIconIdAndTextColor(iconId || '', isActive);

    return (
      <>
        {!sidebarOpen && (
          <Tooltip
            anchorSelect={`#${id}`}
            place="right"
            opacity={100}
            style={{borderRadius: '6px'}}
          >
            {props.children}
          </Tooltip>
        )}
        <a href={pagePath} onClick={handleMobileSideNav} style={{textDecoration: 'none'}}>
          <button
            type="button"
            id={id}
            className={getClasses(isActive, isMobileView, excludeIcon)}
            // In-line style necessary to override admin page stylesheet
            style={{borderRadius: '6px', padding: '8px 12px'}}
          >
            {!excludeIcon && <ComptSvgIcon iconName={iconIdColor} />}
            <Transition
              show={sidebarOpen || !!props.isSecondaryButton}
              enter="transition-opacity duration-300 delay-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <p className={`body2 ${excludeIcon ? '' : 'pl-3'} flex whitespace-nowrap`}>
                {props.children}
              </p>
            </Transition>
          </button>
        </a>
      </>
    );
  }

  return (
    <NavLink to={pagePath} onClick={handleMobileSideNav}>
      {({isActive}) => {
        const {iconIdColor} = getIconIdAndTextColor(iconId || '', isActive);

        return (
          <>
            {!sidebarOpen && (
              <Tooltip
                anchorSelect={`#${id}`}
                place="right"
                opacity={100}
                style={{borderRadius: '6px'}}
              >
                {props.children}
              </Tooltip>
            )}
            <button
              type="button"
              id={id}
              className={twMerge(getClasses(isActive, isMobileView, excludeIcon), 'py-2 px-3')}
            >
              <div className="flex justify-center">
                {!excludeIcon && <ComptSvgIcon iconName={iconIdColor} />}
              </div>
              <Transition
                show={sidebarOpen || !!props.isSecondaryButton}
                enter="transition-opacity duration-300 delay-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <p
                  className={`body2 ${
                    excludeIcon ? '' : 'pl-3'
                  } flex justify-start whitespace-nowrap`}
                >
                  {props.children}
                </p>
              </Transition>
            </button>
          </>
        );
      }}
    </NavLink>
  );
};
