import React, {Dispatch, SetStateAction} from 'react';

// RTK queries
import {useCreatePreApprovalRequestFieldMutation} from '@compt/app/services/api/learning-development-slice';

// Hooks and methods
import {useForm} from 'react-hook-form';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

// Types
import {CustomFieldFormFieldValues} from './custom-field-form.types';
import {LearningDevelopmentProgram} from '@compt/types/learning-development/learning-development-program';

// Components
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {CustomFieldForm} from './custom-field-form';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';

interface CustomFieldSidePanelProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  programData: LearningDevelopmentProgram;
}

export const CreateCustomFieldSidePanel = (props: CustomFieldSidePanelProps) => {
  const formMethods = useForm<CustomFieldFormFieldValues>({
    defaultValues: {
      field_name: undefined,
      supporting_text: undefined,
      field_type: undefined,
      is_required_field: undefined,
    },
  });

  const [createRequestField] = useCreatePreApprovalRequestFieldMutation();

  function onSubmit(form: CustomFieldFormFieldValues) {
    if (!form.field_name || !form.field_type || !form.is_required_field) return;

    const submission = {
      programId: props.programData.id,
      companyId: props.programData.company.id,
      configurationId: props.programData.pre_approval_configuration.id,
      body: {
        field_name: form.field_name,
        supporting_text: form.supporting_text,
        field_type: form.field_type,
        is_active: true,
        is_required: form.is_required_field === 'Y',
        sequence: props.programData.pre_approval_configuration.request_fields.length ?? 0,
      },
    };

    createRequestField(submission).then((results) => {
      if ('error' in results) {
        triggerCustomToast('error', 'There was a problem creating your custom field');
        return;
      }

      formMethods.reset({
        field_name: null,
        supporting_text: null,
        field_type: null,
        is_required_field: null,
      });
      props.setOpen(false);
    });
  }

  function onCancel() {
    formMethods.reset({
      field_name: null,
      supporting_text: null,
      is_required_field: null,
      field_type: null,
    });
    props.setOpen(false);
  }

  return (
    <ComptSidePanel open={props.open}>
      <ComptSidePanel.Header title="Add a custom field" setOpen={onCancel} />
      <ComptSidePanel.Content className="px-4 py-6 sm:px-6">
        <CustomFieldForm formMethods={formMethods} />
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        <ComptButton onClick={formMethods.handleSubmit(onSubmit)}>Create</ComptButton>
        <ComptButton buttonType={ComptButtonType.BORDERLESS} onClick={onCancel}>
          Cancel
        </ComptButton>
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};
