import React from 'react';

// Hooks and methods
import {generateLocalStorageKey, useCacheableObjectState} from '@compt/utils/local-storage-helper';

// Components
import {LndDropDown} from '@compt/pages/admin-pages/admin-review-lnd/components/review-lnd-dropdown';

interface SidebarCollapsibleProps<TOptionType> {
  collapsibleId: string;
  label: string;
  options: TOptionType[];
  getOptionKey: (option: TOptionType) => string | number;
  getOptionLabel: (option: TOptionType) => string;
  handleSelectionChange: (optionId: string, isChecked: boolean) => void;
  selectedOptions: string[];
  testId?: string;
}

export const SidebarCollapsible = <TOptionType,>(props: SidebarCollapsibleProps<TOptionType>) => {
  const [collapsibleState, setCollapsibleState] = useCacheableObjectState(
    generateLocalStorageKey(props.collapsibleId, 'filter-sidebar-dropdown'),
    {isOpen: true},
    false,
  );

  return (
    <div className="mb-2" key={props.collapsibleId}>
      <LndDropDown
        id={`sidebar-${props.collapsibleId}-filter`}
        data-testid={props.testId}
        value={{id: props.collapsibleId, label: props.label}}
        onClick={() => setCollapsibleState((prevState) => ({isOpen: !prevState.isOpen}))}
        isSelected={collapsibleState.isOpen}
      />
      {collapsibleState.isOpen && (
        <div className="flex flex-col my-3 space-y-2 pl-2">
          {props.options.map((option: TOptionType) => (
            <label key={props.getOptionKey(option)} className="flex items-center space-x-1">
              <input
                type="checkbox"
                checked={props.selectedOptions?.includes(props.getOptionKey(option) as string)}
                onChange={(e) =>
                  props.handleSelectionChange(
                    props.getOptionKey(option) as string,
                    e.target.checked,
                  )
                }
                className={`h-4 w-4 rounded border-gray-300 text-color-link checked:bg-base-500
                            focus:ring-base-500 cursor-pointer mr-100 disabled:bg-gray-400 
                            disabled:hover:bg-gray-400`}
              />
              <span className="text-sm text-gray-700">{props.getOptionLabel(option)}</span>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};
