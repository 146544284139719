import React from 'react';
import {
  BusinessExpense,
  BusinessExpenseType,
  expenseTypeFormats,
} from '@compt/types/business-expenses/business-expense';
import {formatCurrencyFromCountryCode} from '@compt/utils/international-helpers';
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';
import {SimpleAction, SimpleActionMenu} from '@compt/common/compt-table/simple-action-menu';
import {
  BusinessExpenseStatus,
  businessExpenseStatusFormat,
} from '@compt/types/business-expenses/business-expense-statuses';
import {ComptPill} from '@compt/common/forms/compt-pill/compt-pill';

export class AdminBETableController {
  static getColumnDefinition(
    onActionMenuClicked: (expense: BusinessExpense, action: 'VIEW' | 'APPROVE' | 'REJECT') => void,
  ) {
    const columnDefinition = {
      receipt_date: {
        id: 'receipt_date',
        name: 'Expense date',
        grow: 0.75,
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents" data-testid={`admin-be-row-${expense.id}`}>
            {formattedDate(expense.receipt_date, DATE_FORMAT_OPTION['month dd yyyy']) ||
              formattedDate(expense.start_date, DATE_FORMAT_OPTION['month dd yyyy'])}
          </p>
        ),
        order: 1,
        omit: false,
      },
      expense_type: {
        id: 'expense_type',
        name: 'Expense type',
        grow: 1,
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">{expenseTypeFormats[expense.expense_type]}</p>
        ),
        order: 2,
        omit: false,
      },
      vendor: {
        id: 'vendor',
        name: 'Vendor/Mileage',
        grow: 0.75,
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">
            {expense.expense_type === BusinessExpenseType.MILEAGE
              ? (expense.distance as string)
              : expense.vendor
              ? (expense.vendor as string)
              : '-'}
          </p>
        ),
        order: 3,
        omit: false,
      },
      amount_of_expense: {
        id: 'amount_of_expense',
        name: 'Amount',
        grow: 0.25,
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">
            {formatCurrencyFromCountryCode(expense.amount_of_expense, 'US')}
          </p>
        ),
        order: 4,
        omit: false,
      },
      description: {
        id: 'description',
        name: 'Expense summary',
        grow: 2,
        selector: (expense: BusinessExpense) => (
          <p data-tag="allowRowEvents">{expense.description}</p>
        ),
        format: (expense: BusinessExpense) => {
          if (expense.description.length < 50) {
            return expense.description;
          }

          return `${expense.description.slice(0, 50)}...`;
        },
        order: 5,
        omit: false,
      },
      status: {
        id: 'status',
        name: 'Status',
        grow: 1.25,
        selector: (expense: BusinessExpense) => {
          const statusFormat = businessExpenseStatusFormat[expense.status];

          return (
            <ComptPill pillType={statusFormat.statusStyle} data-tag="allowRowEvents">
              {statusFormat.statusName}
            </ComptPill>
          );
        },
        order: 6,
      },
      action: {
        id: 'action',
        ignoreRowClick: true,
        compact: true,
        selector: (expense: BusinessExpense) =>
          this.renderActionSelector(expense, onActionMenuClicked),
        grow: 0,
        order: 7,
      },
    };

    return columnDefinition;
  }

  static renderActionSelector(
    expense: BusinessExpense,
    onActionMenuClicked: (expense: BusinessExpense, action: 'VIEW' | 'APPROVE' | 'REJECT') => void,
  ) {
    const _onMenuClicked = (expense: BusinessExpense, action: 'VIEW' | 'APPROVE' | 'REJECT') => {
      onActionMenuClicked(expense, action);
    };
    const actions: Array<SimpleAction<BusinessExpense>> = [
      {
        label: 'View',
        onClick: (expense) => _onMenuClicked(expense, 'VIEW'),
      },
    ];

    if (
      expense.status !== BusinessExpenseStatus.APPROVED &&
      expense.status !== BusinessExpenseStatus.REJECTED &&
      expense.status !== BusinessExpenseStatus.PROCESSED
    ) {
      actions.push({
        label: 'Approve',
        onClick: (expense) => _onMenuClicked(expense, 'APPROVE'),
      });
      actions.push({
        label: 'Reject',
        onClick: (expense) => _onMenuClicked(expense, 'REJECT'),
        textColor: 'text-color-error',
      });
    }

    return <SimpleActionMenu relatedActionItem={expense} actions={actions} />;
  }
}
