import {DateString} from './common/date-string';
import {ListResultsBase} from './common/list-results';

export enum LearningReviewReaction {
  LIKE = 'LIKE',
  LOVE = 'LOVE',
  INSIGHTFUL = 'INSIGHTFUL',
}

export interface LearningCourseReview {
  id: number;
  review_title: string;
  review_description: string;
  rating: number;
  course_url: string;
  authors_industry: string;
  authors_title_when_submitted: string | null;
  user_entered_keywords: string[];
  user_reaction: {id: number; reaction_type: LearningReviewReaction | null};
  total_reactions: number;
  created_on: DateString;
  modified_on: DateString;
  linkedin_account?: string;
}

export type LearningCourseReviewListResults = ListResultsBase<LearningCourseReview>;

export interface EmployeeReview extends LearningCourseReview {
  request_id: number;
}

export interface LearningReviewPayload {
  review_title: LearningCourseReview['review_title'];
  review_description: LearningCourseReview['review_description'];
  rating: LearningCourseReview['rating'];
  user_entered_keywords: LearningCourseReview['user_entered_keywords'];
  request_id: number;
}
